import {EventEmitter, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {FichePreparationModel} from '../models/fichePreparation';
import {BaseService} from './base.service';
import {map} from 'rxjs/operators';
import {EventModel} from '../models/event.model';
import {MoyenModel} from '../models/moyens/moyen.model';
import {DocumentModel} from '../models/fichePreparation/document.model';
import {FicheCheckResult} from '../models/fichePreparation/fiche-check-result.model';

@Injectable({providedIn: 'root'})
export class FichePreparationService extends BaseService {

  public onReloadList: EventEmitter<number> = new EventEmitter();

  getListFichePreparation(data): Observable<any> {
    return this.post(environment.apiPath + 'fiche-preparation/list/false', data).pipe(
      map(item => {
        return {data: item['fichePreparations'], nbItem: item['nombreTotalFiches']};
      }),
    );
  }
  getListTypeFichePreparation(data): Observable<any> {
    return this.post(environment.apiPath + 'fiche-preparation/list/true', data).pipe(
      map(item => {
        return {data: item['fichePreparations'], nbItem: item['nombreTotalFiches']};
      }),
    );
  }

  getListFichePreparationGrouped(data): Observable<any> {
    return this.post(environment.apiPath + 'fiche-preparation/listGrouped/', data).pipe(
      map(item => {
        return {data: item['fichePreparations'], nbItem: item['nombreTotalFiches']};
      }),
    );
  }

  getFichePreparation(id): Observable<FichePreparationModel> {
    return this.get(environment.apiPath + 'fiche-preparation/get/' + id).pipe(
      map(item => {
        return item as FichePreparationModel;
      }),
    );
  }
  getFicheTypePreparation(id): Observable<FichePreparationModel> {
    return this.get(environment.apiPath + 'fiche-preparation/getFicheType/' + id).pipe(
      map(item => {
        return item as FichePreparationModel;
      }),
    );
  }

  postCheckFichePrep(data: FichePreparationModel): Observable<FicheCheckResult> {
    return this.post(environment.apiPath + 'fiche-preparation/check/', data).pipe(
      map(item => {
        return item as FicheCheckResult;
      }),
    );
  }

  getZipFichePrep(ids: number[], name: String): Observable<any> {
    if (name === 'prepa') {
      return this.http.post(environment.apiPath + 'fiche-preparation/getMultipleFichePreparationPrint/',
        ids, {responseType: 'arraybuffer'}).pipe(
        map(item => {
          return item as any
        }),
      );
    }
    if (name === 'cr') {
      return this.http.post(environment.apiPath + 'fiche-preparation/getMultipleFichePreparationPrintCR/',
        ids, {responseType: 'arraybuffer'}).pipe(
        map(item => {
          return item as any
        }),
      );
    }
    if (name === 'etiquette') {
      return this.http.post(environment.apiPath + 'fiche-preparation/getMultipleFichePreparationPrintEtiquette/', ids, {responseType: 'arraybuffer'}).pipe(
        map(item => {
          return item as any
        }),
      );
    }
  }

  downloadMultiple(ids: number[], name: String): void {
    this.getZipFichePrep(ids, name).subscribe(zip => {
      const blob = new Blob([zip], {type: 'application/zip'});
      const url = window.URL.createObjectURL(blob);
      const fileName = url;
      const downloadElement = document.createElement('a');
      downloadElement.href = fileName;
      downloadElement.setAttribute('download', 'fiches_preparation_telechargé');
      downloadElement.click();
    });
  }

  postCheckForOpFichePrep(data): Observable<any> {
    return this.post(environment.apiPath + 'fiche-preparation/checkForOp/', data).pipe(
      map(item => {
        return item as any;
      }),
    );
  }

  updateFichePreparation(fichePreparation): Observable<String> {
    return this.post(environment.apiPath + 'fiche-preparation/update/', fichePreparation).pipe(
      map(item => {
        return item as string;
      }),
    );
  }
  updateFichePreparationType(fichePreparation): Observable<String> {
    return this.post(environment.apiPath + 'fiche-preparation/updateFicheType/', fichePreparation).pipe(
      map(item => {
        return item as string;
      }),
    );
  }

  getMaxOp(num_avis, no_ot_at): Observable<number> {
    return this.get(environment.apiPath + 'fiche-preparation/getMaxOp/' + num_avis + '-' + no_ot_at).pipe(
      map(item => {
        return item as number;
      }),
    );
  }

  public deleteDocTmp(path: string): Observable<any> {
    return this.post(environment.apiPath + 'fiche-preparation/deleteTmp/' + path, path).pipe(
      map(data => {
        return data['data'] as string;
      }),
    );
  }

  updateDocument(document: DocumentModel) {
    const url = `${environment.apiPath}document`;
    return this.http.post(url, document);
  }

  deleteDocument(path: string): Observable<string> {
    return this.post(environment.apiPath + 'fiche-preparation/delete/' + path, path).pipe(
      map(data => {
        return data['data'] as string;
      }),
    );
  }
  deleteDocumentType(path: string): Observable<string> {
    return this.post(environment.apiPath + 'fiche-preparation/deleteType/' + path, path).pipe(
      map(data => {
        return data['data'] as string;
      }),
    );
  }

  uploadDocument(doc: File, fiche_preparation_id, typedoc, isType: boolean): Observable<string> {
    const formData: FormData = new FormData();
    formData.append('file', doc, doc.name);
    formData.append('fiche_preparation_id', fiche_preparation_id);
    formData.append('typedocument', typedoc);
    if (isType) {
      formData.append('isType', 'true');
    } else {
      formData.append('isType', 'false');
    }
    return this.postFile(environment.apiPath + 'fiche-preparation/import-image/', formData).pipe(
      map(data => {
        return data['data'] as string;
      }),
    );
  }

  copyDocumentType(fiche_preparation_id, fiche_type_id, typedoc, name: string): Observable<string> {
    const formData: FormData = new FormData();
    formData.append('name', name);
    formData.append('fiche_preparation_id', fiche_preparation_id);
    formData.append('fiche_type_id', fiche_type_id);
    formData.append('typedocument', typedoc);
    return this.postFile(environment.apiPath + 'fiche-preparation/save-image-from-type/', formData).pipe(
      map(data => {
        return data['data'] as string;
      }),
    );
  }


  insertFichePreparation(usine): Observable<number> {
    return this.post(environment.apiPath + 'fiche-preparation/insert/', usine).pipe(
      map(item => {
        return item['inserted_id'];
      }),
    )
  }

  public getFiches(title, atelier): Observable<any> {
    return this.get(environment.apiPath + 'fiche-preparation/getFiches/' + title + '/' + atelier).pipe(
      map(item => {
        return item;
      }));
  }

  public getFichesCri(title, idAtelier): Observable<any> {
    return this.get(environment.apiPath + 'fiche-preparation/fichescri/' + title + '/' + idAtelier).pipe(
      map(item => {
        return item;
      }));
  }

  public getFichesCriWithMachine(machineUnique, title, idAtelier): Observable<any> {
    return this.get(environment.apiPath + 'fiche-preparation/fichescri/' + machineUnique + '/' + title + '/' + idAtelier).pipe(
      map(item => {
        return item;
      }));
  }

  public getFichesByMachine(machineUnique, title, atelier): Observable<any> {
    return this.get(environment.apiPath + 'fiche-preparation/getFichesByMachine/' + machineUnique + '/' + title + '/' + atelier).pipe(
      map(item => {
        return item;
      }));
  }

  public deleteFiche(idFiche): Observable<any> {
    return this.delete(environment.apiPath + 'fiche-preparation/delete/' + idFiche, true).pipe(
      map(item => {
        if (this.onReloadList != null) {
          this.onReloadList.emit(idFiche);
        }
        return item;
      }));
  }
  public deleteFicheType(idFicheType): Observable<any> {
    return this.delete(environment.apiPath + 'fiche-preparation/deleteFicheType/' + idFicheType, true).pipe(
      map(item => {
        if (this.onReloadList != null) {
          this.onReloadList.emit(idFicheType);
        }
        return item;
      }));
  }

  public updateStatus(status): Observable<any> {
    return this.put(environment.apiPath + 'fiche-preparation/update-status/', status).pipe(
      map(item => {
        return item;
      }));
  }

  public updateDateDebut(eventFiche: EventModel): Observable<number> {
    return this.put(environment.apiPath + 'fiche-preparation/update-date-debut/', eventFiche).pipe(
      map(item => {
        return item as number;
      }));
  }

  public updateDateFin(eventFiche: EventModel): Observable<number> {
    return this.put(environment.apiPath + 'fiche-preparation/update-date-fin/', eventFiche).pipe(
      map(item => {
        return item as number;
      }));
  }

  public putReport(report): Observable<any> {
    return this.put(environment.apiPath + 'fiche-preparation/report/', report).pipe(
      map(item => {
        return item;
      }));
  }

  public removeRowFromMoyens(listMoyens: MoyenModel[], row) {
    listMoyens.splice(listMoyens.indexOf(row), 1);
    return listMoyens;
  }
}
